.bg-atelje {
    background-color: #ec173a;
    color: #fff;
}

.box-atelje {
    background-color: #ec173a;
    color: #fff;
    border-radius: 25px;
    padding: 2rem;
}

.btn-atelje-sec {
    background-color: #ffffff;
    color: #ec173a;
}

.btn-atelje-sec:hover {
    background-color: #ffd9a8;
    color: #C42335;
}

.btn-icon {
    display: inline-block;
    float: right;
    height: 85%;
    width: 35%;
}

.btn-txt {
    display: inline-block;
    font-size: 24px; 
    height: 100%;
    width: 60%;
}

.fg-nav-atelje {
    color: #fff;
}

.fg-nav-atelje:hover {
    color: #ffd9a8;
}

#nav-atelje {
    background-color: #ec173a;
    color: #fff;
}

#nav-atelje:hover {
    color: #ffd9a8;
}

div.dropdown-menu.show {
    background-color: #ec173a; 
}

a.dropdown-item {
    color: #ffffff;  
}

a.dropdown-item:hover {
    background-color: #ec173a;
    color: #ffd9a8;  
}

.social-media-icon {
    color: #fff;
}

.social-media-icon:hover {
    color: #fdecdd;
}

.btn-atelje {
    background-color: #ec173a;
    transition: 0.7s;
    border: 0px;
    background-size: 200% auto;
    color: #fff;
    width: 150px;
    height: 50px;
    border-radius: 12px;
    margin: 0px;
}

.btn-atelje:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
}

.acrsc {
    background-color: #ec173a;
    border: 0px;
    color: #fff;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
}

