.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  .icon-style{
    background-color: #ec173a;
    border: 1px solid #fff;
    border-radius: 12px;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    background: #ffffff;
    color: #ec173a;
    border: 1px solid #ffff;
  }

  .social-media {
    position: fixed;
    top: 20%;
    width: 60px;
    margin: 0px;
    color: #fff;
    z-index: 20;
  }

  .yt-bg {
    color:#ec173a;
    background: #fff;
    height: 50px;
    width: 50px;
    font-size: 50px;
    border-top-right-radius: 35%;
  }

  .fb-bg {
    color:#ec173a;
    background: #fff;
    height: 50px;
    width: 50px;
    font-size: 50px;
  }

  .ig-bg{
    color:#ec173a;
    background: #fff;
    height: 50px;
    width: 50px;
    font-size: 50px;
  }
 
  .ta-bg{
    color:#ec173a;
    background: #fff;
    height: 50px;
    width: 50px;
    font-size: 50px;
    border-bottom-right-radius: 35%;
  }

  .ta-bg:hover, .ig-bg:hover, .fb-bg:hover, .yt-bg:hover {
    background: #fdecdd;
}

@media screen and (max-width: 600px) {
  .social-media, .ta-bg, .fb-bg, .ig-bg, .yt-bg {
    visibility: hidden;
    display: none;
  }
}